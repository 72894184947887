import { useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

const useIsMobile = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));
  const isLandscape = useMediaQuery(theme.breakpoints.down(900));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const isMobileClasses = ({
    webClass = "",
    tabletClass = "",
    landClass="",
    mobileClass = "",
    notIsMobileClass = "",
  }) => {
    return clsx(webClass, {
      [tabletClass]: isTablet,
      [landClass]: isLandscape,
      [mobileClass]: isMobile,
      [notIsMobileClass]: !isMobile,
    });
  };

  return {
    isMobile,
    isLandscape,
    isTablet,
    isMobileClasses,
  };
};

export default useIsMobile;
