import React from "react";
import { Logo } from "../../images/Logo";
import { IconButton, MenuItem, MenuList } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";
import MenuIcon from "../MenuIcon";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "../../images/CloseIcon";
import Whatsapp from "../../images/Whatsapp";
import Instagram from "../../images/Instagram";
import LinkedIn from "../../images/LinkedIn";
import Facebook from "../../images/Facebook";
import useFetchSocial from "../../hooks/useFetchSocial";
import { homeLink } from "../../../Utils/costant";
import CustomText from "../CustomText/CustomText";

interface Props {
  pathname: string;
  buttons: buttonsType[];
  currPage: string;
}

type buttonsType = {
  label: string;
  link: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    margin: "auto 0",
  },
  button: {
    ...theme.customText.h3People,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    lineHeight: "42px",
    "&:hover": {
      background: "none",
      textDecoration: "none",
    },
  },
  paper: {
    width: "100%",
    background: theme.colors.entertainment,
    padding: "22px",
  },
  logo: {
    width: 45,
    height: 45,
    marginRight: "auto",
  },
  logoBackContainer: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    ...theme.customText.paragrafo,
    marginBottom: 14,
    marginRight: 8,
  },
  ml3: {
    marginLeft: theme.spacing(4),
  },
  bottomContainer: {
    marginTop: "auto",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  aree: {
    textAlign: "center",
    margin: "0px auto",
    width: "max-content",
  },
  socialContainer: {
    width: "100%",
    maxWidth: 222,
    justifyContent: "space-between",
    display: "flex",
    margin: "48px auto",
  },
  linkText: {
    padding: "16px 10px",
    color: theme.colors.black,
    border: `1px solid ${theme.colors.black}`,
  },
  selected: {
    color: theme.colors.white,
    textDecoration: "underline",
  },
}));

const MobileMenu = (props: Props) => {
  const { buttons, pathname } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { social } = useFetchSocial();

  const getIcon = (value) => {
    switch (value) {
      case "whatsapp":
        return <Whatsapp />;
      case "instagram":
        return <Instagram />;
      case "linkedin":
        return <LinkedIn />;
      case "facebook":
        return <Facebook />;
      default:
        return null;
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const handleClickItem = (fn: () => void) => {
    if (typeof fn === "function") fn();
    toggleDrawer(false);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{ paper: classes.paper }}
      >
        <>
          <div className={classes.logoBackContainer}>
            <Logo className={classes.logo} />
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <MenuList
            autoFocusItem={open}
            id="menu-list-grow"
            className={classes.menuList}
          >
            {buttons.map((btn, idx) => {
              return (
                <MenuItem
                  disableRipple
                  disableTouchRipple
                  component={Link}
                  onClick={() => handleClickItem(btn.onClick)}
                  to={btn.link}
                  key={btn.label}
                  className={clsx(classes.button, {
                    [classes.selected]: pathname === btn.link,
                    [classes.ml3]: idx === 0 || idx === 2 || idx === 4,
                  })}
                >
                  <span className={classes.number}>{`0${idx + 1}`}</span>
                  {btn.label}
                </MenuItem>
              );
            })}
          </MenuList>
          <div className={classes.bottomContainer}>
            <CustomText type="payoff" className={classes.aree}>
              <a
                className={clsx(classes.linkText, classes.link)}
                href={homeLink}
              >
                LE NOSTRE AREE
              </a>
            </CustomText>
            <div className={classes.socialContainer}>
              {social.map((item) => (
                <a
                  key={item.node.id}
                  className={classes.link}
                  href={item.node?.linkEnt}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getIcon(item.node.nomeSocial.toLowerCase())}
                </a>
              ))}
            </div>
          </div>
        </>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
