import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby-theme-material-ui";
import { Logo } from "../../images/Logo";
import MobileMenu from "../MobileMenu/MobileMenu";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText";

interface Props {
  currPage?: string;
  position?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  button: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&:hover": {
      color: theme.colors.entertainment,
      background: "none",
    },
  },
  logo: {
    marginRight: 30,
    [theme.breakpoints.down("md")]: {
      width: 45,
      minWidth: 45,
      height: 45,
      marginRight: 18,
    },
  },
  toolbar: {
    margin: "22px auto",
    width: "100%",
    maxWidth: 1600,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  backgroundRed: {
    background: theme.colors.entertainment,
  },
  backTransparent: {
    background: "transparent",
  },
  buttonIsTransparent: {
    color: theme.colors.white,
    "&:hover": {
      color: theme.colors.entertainment,
      background: "none",
    },
  },
  buttonAboutUs: {
    color: theme.colors.white,
    "&:hover": {
      color: theme.colors.black,
      background: "none",
    },
  },
  aboutUsContent: {
    height: 503,
    alignItems: "flex-end",
    paddingBottom: 63,
    maxWidth: 1600,
    width: "100%",
    padding: "100px 0px",
    margin: "0px auto",
    [theme.breakpoints.down(1720)]: {
      padding: "100px clamp(16px, 8.281vw, 159px)",
    },
  },
  subtitle: {
    lineHeight: "36px",
  },
  commonSubtitleName: {
    letterSpacing: -2.5,
  },
  subtitleName: {
    height: 136,
    width: 596,
    lineHeight: "68px",
  },
  nameContent: {
    flexGrow: 1,
  },
  logoAboutUs: {
    height: 194,
    width: 194,
    minWidth: 194,
  },
  mobileAboutUs: {
    alignItems: "flex-start",
    minHeight: 410,
    display: "inline-grid",
    alignContent: "space-between",
  },
  mobileLogoAboutUs: {
    height: 104,
    width: 104,
    minWidth: 104,
  },
  mobileSubtitleName: {
    lineHeight: "36px",
    [theme.breakpoints.down(410)]: {
      lineHeight: "34px",
    },
  },
  mobileSubtitle: {
    lineHeight: "23px",
    marginBottom: theme.spacing(2),
  },
  active: {
    color: theme.colors.entertainment,
  },
  alternativeActive: {
    color: theme.colors.black,
  },
  linkLogo: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  white: {
    color: theme.colors.white,
  },
}));

const MainAppbar = ({ currPage, position = "static" }: Props) => {
  const classes = useStyles();
  const { isMobileClasses, isMobile, isLandscape } = useIsMobile();
  const pathname =
    typeof window !== `undefined` ? window.location.pathname : "";
  const isTransparent = useMemo(() => currPage === "transparent", [currPage]);

  const isCustomBackground = useMemo(
    () => currPage === "aboutUs" || currPage === "contactUs",
    [currPage]
  );
  const buttons = [
    {
      label: "Home",
      link: "/",
      onClick: () => {},
    },
    {
      label: "Chi Siamo",
      link: "/about-us",
      onClick: () => {},
    },
    {
      label: "Contatti",
      link: "/contact-us",
      onClick: () => {},
    },
  ];

  const buttonsArray = buttons.map((btn) => {
    const isSelected = btn.link === pathname;
    return (
      <Link to={btn.link} key={btn.label} className={classes.link}>
        <Button
          disableRipple
          className={clsx(classes.button, {
            [classes.buttonAboutUs]: isCustomBackground,
            [classes.buttonIsTransparent]: isTransparent,
            [classes.active]: isSelected && !isCustomBackground,
            [classes.alternativeActive]: isSelected && isCustomBackground,
          })}
          onClick={btn.onClick}
        >
          {btn.label}
        </Button>
      </Link>
    );
  });

  const aboutUsTitle = (
    <div className={classes.nameContent}>
      <CustomText
        type="payoff"
        className={isMobileClasses({
          notIsMobileClass: classes.subtitle,
          mobileClass: classes.mobileSubtitle,
        })}
      >
        THIS IS OUR
      </CustomText>
      <CustomText
        type="titoloH1"
        className={isMobileClasses({
          webClass: classes.commonSubtitleName,
          notIsMobileClass: classes.subtitleName,
          mobileClass: classes.mobileSubtitleName,
        })}
      >
        VERTEEGO ENTERTAINMENT
      </CustomText>
    </div>
  );

  return (
    <AppBar
      color="primary"
      position={position}
      elevation={0}
      className={clsx({
        [classes.backgroundRed]: isCustomBackground,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={clsx(classes.link, classes.linkLogo)}>
          <Logo className={classes.logo} />
          <CustomText type="payoff2" className={classes.title}>
            Entertainment
          </CustomText>
        </Link>
        {isMobile ? (
          <MobileMenu
            pathname={pathname}
            buttons={buttons}
            currPage={currPage}
          />
        ) : (
          buttonsArray
        )}
      </Toolbar>
      {currPage === "aboutUs" && (
        <Toolbar
          className={isMobileClasses({
            webClass: clsx(classes.toolbar, classes.backgroundRed),
            notIsMobileClass: classes.aboutUsContent,
            mobileClass: classes.mobileAboutUs,
          })}
        >
          {!isLandscape ? (
            <>
              {aboutUsTitle}
              <Logo className={classes.logoAboutUs} />
            </>
          ) : (
            <div className={classes.mobileAboutUs}>
              <Logo className={classes.mobileLogoAboutUs} />
              {aboutUsTitle}
            </div>
          )}
        </Toolbar>
      )}
    </AppBar>
  );
};

export default MainAppbar;
