import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

const CloseIcon = (props: SvgProps) => {
  const { fill = "black", ...rest } = props;
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" {...rest}>
      <path
        d="M8.40994 6.49994L12.7099 2.20994C12.8982 2.02164 13.004 1.76624 13.004 1.49994C13.004 1.23364 12.8982 0.978245 12.7099 0.789941C12.5216 0.601638 12.2662 0.49585 11.9999 0.49585C11.7336 0.49585 11.4782 0.601638 11.2899 0.789941L6.99994 5.08994L2.70994 0.789941C2.52164 0.601638 2.26624 0.49585 1.99994 0.49585C1.73364 0.49585 1.47824 0.601638 1.28994 0.789941C1.10164 0.978245 0.995847 1.23364 0.995847 1.49994C0.995847 1.76624 1.10164 2.02164 1.28994 2.20994L5.58994 6.49994L1.28994 10.7899C1.19621 10.8829 1.12182 10.9935 1.07105 11.1154C1.02028 11.2372 0.994141 11.3679 0.994141 11.4999C0.994141 11.632 1.02028 11.7627 1.07105 11.8845C1.12182 12.0064 1.19621 12.117 1.28994 12.2099C1.3829 12.3037 1.4935 12.3781 1.61536 12.4288C1.73722 12.4796 1.86793 12.5057 1.99994 12.5057C2.13195 12.5057 2.26266 12.4796 2.38452 12.4288C2.50638 12.3781 2.61698 12.3037 2.70994 12.2099L6.99994 7.90994L11.2899 12.2099C11.3829 12.3037 11.4935 12.3781 11.6154 12.4288C11.7372 12.4796 11.8679 12.5057 11.9999 12.5057C12.132 12.5057 12.2627 12.4796 12.3845 12.4288C12.5064 12.3781 12.617 12.3037 12.7099 12.2099C12.8037 12.117 12.8781 12.0064 12.9288 11.8845C12.9796 11.7627 13.0057 11.632 13.0057 11.4999C13.0057 11.3679 12.9796 11.2372 12.9288 11.1154C12.8781 10.9935 12.8037 10.8829 12.7099 10.7899L8.40994 6.49994Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
